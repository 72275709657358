import React from 'react';
import './App.css';
import { Authenticator, ThemeProvider, createTheme } from '@aws-amplify/ui-react';

function App() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme} colorMode={'system'}>
      <Authenticator>
        <div></div>
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
